/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import DashboardManageContainer from '../../containers/DashboardManage';
import { MessageBox } from '../MessageBox';
import ToggleView from './ToggleView';
import StaticTab from './StaticTab';
import SearchTab from './SearchTab';
import LinkTab from './LinkTab';
import CollectionDashboardOptions from '../CollectionDashboardOptions';
import { Translation } from '../Text';
import CheckForOverflow from '../CheckForOverflow';
import Tooltip from '../Tooltip';
import CollectionMenu from './CollectionMenu';

// Collection amount maximum chosen for current tab auto-open - rendering too many collections causes dashboard to become unresponsive
const COLLECTIONS_AUTO_EXPAND_LIMIT = 250;

class NavigationPanel extends Component {
  static defaultProps = {
    title: 'dashboard.header',
    color: 'grey',
    isNewUser: false,
  };

  state = {
    openCollectionMenu: false,
    current: !!this.props.tabs.currentCollectionTabs.length && this.props.tabs.currentCollectionTabs.length < COLLECTIONS_AUTO_EXPAND_LIMIT,
    archived: false,
    openStaticTabs: false,
    collectionSearchText: '',
  };

  collectionOptionsRef = React.createRef();

  componentDidMount() {
    if (this.props.selectedTab === 'group') {
      this.updateState('openCollectionMenu');
    }
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.tabs.currentCollectionTabs.length !== this.props.tabs.currentCollectionTabs.length) &&
      (prevProps.tabs.currentCollectionTabs.length === 0) &&
      (this.props.tabs.currentCollectionTabs.length < COLLECTIONS_AUTO_EXPAND_LIMIT)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        current: true,
      });
    }
  }

  getSearchHeight = () => {
    let height;
    const collectionSection = this.collectionSection ? this.collectionSection.offsetHeight : 0;
    if (this.staticSection) {
      const totalHeight = document.body.offsetHeight;
      const staticSection = this.staticSection.offsetHeight;
      height = totalHeight - staticSection - 100 - collectionSection; // 100 for header
    }
    return height;
  };

  onSelectGroupTab = (id) => {
    this.collectionOptionsRef.current.forceClear();
    this.props.onSelectGroupTab(id);
    this.toggleCollectionTabs(false);
  }

  toggleCollectionTabs = (bool) => {
    const { current, archived } = this.state;
    if (current !== bool) {
      this.updateState('current', bool);
    }
    if (archived !== bool) {
      this.updateState('archived', bool);
    }
  }

  updateState = (type, val) => {
    const value = typeof val !== 'undefined' ? val : !this.state[type];
    this.setState({
      [type]: value,
    }, () => {
      if (type === 'collectionSearchText' && value) {
        this.toggleCollectionTabs(true);
      }
      if (type === 'openCollectionMenu' && this.props.onLoadCollections) {
        this.props.onLoadCollections();
      }
    });
  }

  handleCollectionCreate = (data) => {
    this.props.onCollectionCreate(data);
  }

  render() {
    const style = {
      height: this.getSearchHeight(),
    };
    const {
      selectedTab,
      view,
      tabs,
      onToggle,
      onRemove,
      onSelect,
      proofTab,
      color,
      title,
      onRemoveAll,
      from,
      manage,
      isNewUser,
      collectionLoadRef,
      onDrop,
      onCollectionRemove,
      onCollectionManage,
      goToPath,
    } = this.props;
    let proofTabData;
    if (proofTab) {
      proofTabData = {
        label: proofTab.title,
        id: 'proof',
      };
    }

    const {
      openCollectionMenu,
      current,
      archived,
      openStaticTabs,
      collectionSearchText,
    } = this.state;

    const collectionMenu = [
      {
        id: 'current',
        onClick: () => this.updateState('current'),
        hide: !current,
        active: current,
        isLoading: collectionLoadRef.current.isLoading,
        onceLoaded: collectionLoadRef.current.onceLoaded,
        tabs: tabs.currentCollectionTabs,
      },
      {
        id: 'archived',
        onClick: () => this.updateState('archived'),
        hide: !archived,
        active: archived,
        isLoading: collectionLoadRef.archived.isLoading,
        onceLoaded: collectionLoadRef.archived.onceLoaded,
        tabs: tabs.archivedCollectionTabs,
      },
    ];

    return (
      <div className={`NavigationPanel NavigationPanel--${color}`}>
        <div
          className="NavigationPanel__static"
          ref={element => (this.staticSection = element)}
        >
          <div className="NavigationPanel__heading">
            <Translation value={title} />
            {(onToggle && view) && (
              <ToggleView
                onToggle={onToggle}
                view={view}
                color={color}
                isManageMode={manage ? manage.showManagePane : false}
                toggleManage={manage.onToggle}
              />
            )}
          </div>
          <DashboardManageContainer
            {...manage}
            from={from}
            color={from === 'team-dashboard' ? 'grey' : 'green'}
          />
          <div className="NavigationPanel__tabs">
            {(!isNewUser || openStaticTabs) &&
              tabs.staticTabs.map(tab => (
                <StaticTab
                  selected={selectedTab === tab.id}
                  tab={tab}
                  onClick={onSelect}
                  key={tab.id}
                  count={tab.proofCount || tab.proofs.length}
                  color={color}
                  from={from}
                />
              ))
            }
            {isNewUser && !openStaticTabs &&
              <MessageBox
                message={<Translation value="dashboard.navigation.new-user.message" />}
                closeLabel={<Translation value="button.ok-got-it" />}
                onClose={() => this.updateState('openStaticTabs')}
              />
            }
          </div>
          <div className="NavigationPanel__separator" />
        </div>
        {isNewUser &&
          <div className="NavigationPanel__new-user">
            <div className="NavigationPanel__heading">
              <Translation value="dashboard.tab.new-user.heading" />
              <InlineSVG
                className="NavigationPanel__arrow"
                src="/img/interface/arrow-down.svg"
              />
            </div>
            <div className="NavigationPanel__tabs">
              {tabs.newUserTabs.map(tab => (
                <LinkTab
                  key={tab.label}
                  variant={color}
                  tab={tab}
                  selected={false}
                />
              ))}
            </div>
            <div className="NavigationPanel__heading">
              <Translation value="dashboard.tab.new-user.video.heading" />
              <InlineSVG
                className="NavigationPanel__arrow"
                src="/img/interface/arrow-down.svg"
              />
            </div>
            <div className="NavigationPanel__tabs">
              <LinkTab
                key={tabs.newUserVideoTab.label}
                variant={color}
                tab={tabs.newUserVideoTab}
                selected={false}
              />
            </div>
          </div>
        }

        <div
          className="NavigationPanel__inner"
          style={style}
        >
          {!isNewUser &&
            <div
              className={classname(
                'NavigationPanel__collection',
                `NavigationPanel__collection--${color}`,
                { NavigationPanel__collection__hidden: !openCollectionMenu }
              )}
            >
              <div
                className="NavigationPanel__heading"
                onClick={() => this.updateState('openCollectionMenu')}
              >
                <Translation value="dashboard.tab.collection" />
                <InlineSVG
                  className="NavigationPanel__arrow"
                  src="/img/interface/arrow-down.svg"
                />
              </div>
              <div className="NavigationPanel__tabs">
                <CollectionDashboardOptions
                  ref={this.collectionOptionsRef}
                  onCollectionCreate={data => this.handleCollectionCreate(data)}
                  onSearch={text => this.updateState('collectionSearchText', text)}
                  from={from}
                  goToPath={goToPath}
                />
                {tabs.groupTabs.map(tab => (
                  <div
                    className="NavigationPanel__tabs NavigationPanel__tabs-group"
                    key={tab.id}
                  >
                    <StaticTab
                      key={tab.id}
                      initialCasing
                      selected={selectedTab === tab.id}
                      tab={tab}
                      onClick={onSelect}
                      color={color}
                      count={tab.proofCount || tab.proofs.length}
                      from={from}
                      tip={false}
                      dropAllowed={tab.canAddProofs}
                      onDrop={item => onDrop(item, tab.id)}
                      onRemove={tab.isEmpty && onCollectionRemove}
                      onManage={tab.canAddProofs && onCollectionManage}
                    />
                  </div>
                ))}
                {collectionMenu.map(menu => (
                  <CollectionMenu
                    key={menu.id}
                    menu={menu}
                    from={from}
                    onRemove={onCollectionRemove}
                    onManage={onCollectionManage}
                    selectedCollection={selectedTab}
                    onDrop={onDrop}
                    color={color}
                    collectionSearchText={collectionSearchText}
                    onSelect={this.onSelectGroupTab}
                  />
                ))}
              </div>
              <div className="NavigationPanel__separator" />
            </div>
          }

          <div className="NavigationPanel__search">
            {(tabs.searchTabs.length > 0 || proofTab) && (
              <div>
                <div
                  className="NavigationPanel__heading"
                  style={{ display: 'flex', whiteSpace: 'nowrap' }} // This is being deleted with new dashboard
                >
                  <div style={{ flexGrow: 1 }}>
                    <Translation value="dashboard.tab.search" />
                  </div>
                  {tabs.searchTabs.length > 1 && onRemoveAll && (
                    <div className="NavigationPanel__heading__close">
                      <CheckForOverflow>
                        {wouldOverflow => (wouldOverflow
                          ? (
                            <Tooltip
                              title={<Translation value="dashboard.tab.remove-all" />}
                              up
                              center
                            >
                              <div
                                className="NavigationPanel__heading__close--icon"
                                onClick={onRemoveAll}
                              >
                                <InlineSVG
                                  src="/img/interface/menu-close-icon.svg"
                                />
                              </div>
                            </Tooltip>
                          )
                          : (
                            <div
                              className="NavigationPanel__heading__close--text"
                              onClick={onRemoveAll}
                            >
                              <Translation value="dashboard.tab.remove-all" />
                            </div>
                          ))
                        }
                      </CheckForOverflow>
                    </div>
                  )}
                </div>
                <div className="NavigationPanel__tabs">
                  {tabs.searchTabs.map(tab => (
                    <SearchTab
                      key={tab.id}
                      selected={selectedTab === tab.id}
                      tab={tab}
                      onClick={onSelect}
                      color={color}
                      onRemove={onRemove}
                    />
                  ))}
                </div>
                {proofTab && (
                  <SearchTab
                    selected={selectedTab === proofTabData.id}
                    tab={proofTabData}
                    onClick={onSelect}
                    onRemove={onRemove}
                    color={color}
                  />
                )}
              </div>
            )}
          </div>
        </div>

      </div>
    );
  }
}

if (process.env.NODE_ENV !== 'production') {
  NavigationPanel.propTypes = {
    onToggle: PropTypes.func,
    view: PropTypes.string,
    selectedTab: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    tabs: PropTypes.objectOf(PropTypes.any).isRequired,
    onRemove: PropTypes.func,
    onSelect: PropTypes.func.isRequired,
    onSelectGroupTab: PropTypes.func,
    proofTab: PropTypes.objectOf(PropTypes.any),
    onRemoveAll: PropTypes.func,
    color: PropTypes.string,
    title: PropTypes.node,
    from: PropTypes.string,
    manage: PropTypes.objectOf(PropTypes.any),
    isNewUser: PropTypes.bool,
    onLoadCollections: PropTypes.func,
  };
}


export default NavigationPanel;
